<template>
  <div class="map" ref="map"></div>
</template>

<script>
export default {
  name: 'Directions',
  props: [
    'address',
    'postcode',
    'origin'
  ],
  watch: {
    origin(value) {
      if (value.length > 5) {
        this.getDirections();
      }
    },
    address(value) {
      if (value.length > 5) {
        this.getDirections();
      }
    },
  },
  methods: {
    getDirections() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDirectionsDebounced();
      }, 800);
    },
    getDirectionsDebounced() {
      // eslint-disable-next-line
    const myLatlng = new google.maps.LatLng(53.226247, -0.883029);
    const mapOptions = {
      zoom: 10,
      center: myLatlng,
      mapTypeId: 'roadmap'
    };
    // eslint-disable-next-line
    const map = new google.maps.Map(this.$refs['map'], mapOptions);
      // eslint-disable-next-line
      const directionsService = new google.maps.DirectionsService();
      // eslint-disable-next-line
      const directionsRenderer = new google.maps.DirectionsRenderer();
      directionsService.route({
        origin: this.origin,
        destination: `${this.address}, United Kindom`,
        travelMode: 'DRIVING',
        // eslint-disable-next-line
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
          directionsRenderer.setMap(map);
          const results = {
            distance: response.routes[0].legs[0].distance.text,
            duration: response.routes[0].legs[0].duration.text,
          }
          this.$emit('routeFound', results);
        }
      });
    },
  },
  mounted() {
    this.getDirections();
  },
};
</script>

<style lang="scss">
  .map {
    height: 300px;
    width: 100%;
  }
</style>