<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Quotation: #{{ quotation.jobNumber }}
          &nbsp;
          Customer ref: #{{ quotation.customerReference }}
          <span v-if="quotation.converted">
            <span v-if="quotation.isRemedial">
              (This remedial has been confirmed)
            </span>
            <span v-else>
              (This quotation has been converted)
            </span>
          </span>
          <span v-else class="float-right">
            <span v-if="quotation.isRemedial">
              Confirm Remedial
            </span>
            <span v-else>
              Convert Quotation
            </span>
          </span>
        </h1>
      </v-col>
    </v-row>
    <v-row class="pt-1">
      <v-col class="col-12 pt-0 pb-0">
        <router-link
          custom v-slot="{ navigate }"
          :to="`/quotations/view/${quotation.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
            <span v-if="quotation.isRemedial">
              Cancel confirming
            </span>
            <span v-else>
              Cancel converting
            </span>
          </v-btn>
        </router-link>
        &nbsp;
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col
        class="col-12 col-md-3 pt-0 pb-2"
        v-if="addNewContact === false">
        <v-select
          hint="Select a contact, or add a new one"
          persistent-hint
          :items="contacts"
          v-model="quotation.siteContactId"
          item-text="name"
          item-value="id"
          label="Contact"
        ></v-select>
        <v-btn
          class="mt-2"
          @click="addNewContact = true"
          x-small>Add New Contact</v-btn>
      </v-col>
      <div class="modal" v-if="addNewContact === true">        
        <v-row>
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.first_name" label="First Name"></v-text-field>
          </v-col>
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.last_name" label="Last Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.tel" label="Tel"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn small @click="saveNewContact">Save contact</v-btn>
            &nbsp;
            <v-btn small @click="cancelNewContact">Cancel</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-text-field
          hint="Please add a site telephone number"
          persistent-hint
          v-model="quotation.siteTel"
          label="Site telephone" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-text-field
          hint="Please add a site email"
          persistent-hint
          v-model="quotation.siteEmail"
          label="Site email" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="quotation.delivery_date"
              label="Work date"
              hint="Add a requested work date"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="deliveryDate"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <label>Special Instructions</label>
        <vue-editor
          id="specialInstructions"
          :editor-toolbar="customToolbar"
          v-model="quotation.specialInstructions" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-textarea
          hint="Add or update notes"
          persistent-hint
          v-model="quotation.notes"
          label="Notes" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-text-field
          label="Purchase Order Number"
          v-model="quotation.purchase_order"
          hint="Please add a customer PO Number"
          persistent-hint></v-text-field>
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <div v-if="quotation.purchase_order_uri.length === 0">
          <v-btn @click="uploadPo = true" small>
            Upload Purchase Order
          </v-btn>
          <v-dialog max-width="700" v-model="uploadPo">
            <v-card>
              <app-upload-po
                :quotationId="quotation.id"
                @closeUploader="completeUpload"></app-upload-po>
              <v-card-actions>
                <v-btn text @click="uploadPo = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div v-else>
          <a
            :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${quotation.purchase_order_uri}`"
            target="_blank">
            <v-btn small>View Purchase Order</v-btn>
          </a>
        </div>
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-text-field label="Start Address" v-model="quotation.start_address" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <v-text-field
          hint="Please check the project address and update if ncecessary"
          persistent-hint
          v-model="quotation.site"
          label="Project address" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2"> 
        <v-text-field
          hint="Please check the site postcode and update if necessary"
          persistent-hint
          v-model="quotation.postCode"
          label="Site postcode" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2"> 
        <v-text-field
          hint="Optionally add a What Three Words location. This must be three words separated by a full stop. i.e. slice.bridge.hello"
          persistent-hint
          placeholder="slice.bridge.hello"
          v-model="quotation.what_three_words"
          label="What Three Words" />
      </v-col>
    </v-row>
    <v-row class="pt-5">
      <v-col class="col-12 col-md-3 pt-0 pb-2">
        <div v-if="quotation.postCode">
          <app-directions
            v-if="quotation.postCode.length >= 3"
            :origin="quotation.start_address"
            :address="quotation.site"
            :postcode="quotation.postCode"
            @routeFound="populateDistances" />
        </div>
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2"> 
        <v-text-field
          hint="Calculated value, update if necessary"
          persistent-hint
          v-model="quotation.distance"
          label="Distance one way" />
      </v-col>
      <v-col class="col-12 col-md-3 pt-0 pb-2"> 
        <v-text-field
          hint="Calculated value, update if necessary"
          persistent-hint
          v-model="quotation.travel_hours"
          label="Travel time one way" />
      </v-col>
    </v-row>
    <v-row
      v-if="quotation.documents"
      class="pt-5">
      <v-col class="col-10">
        <p>
          The following documents have been attached to this quotation. Please indicate which
          ones you would like to send to the work crews.
        </p>
        <v-list
          width="100%">
          <v-list-item
            v-for="(document, index) in quotation.documents"
            :key="index">
            <v-row>
              <v-col class="col-9 pt-8">
                <a
                  :title="document.title"
                  :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${document.uri}`"
                  target="_blank">
                  {{ document.uri.substring(document.uri.indexOf("__") + 2) }}
                </a>
              </v-col>
              <v-col class="col-md-3">
                <v-checkbox v-model="document.forCrews" label="Show to crews" />
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 col-md-3">
        <v-btn
          large
          @click="checkPostcode">
          <span v-if="quotation.isRemedial">
              Confirm Remedial
            </span>
            <span v-else>
              Convert Quotation
            </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showCheckBox"
      max-width="800"
    >
      <v-card>
        <v-card-title>
          Confirm Job Details
        </v-card-title>
        <v-card-text class="pt-4">
          Important, please confirm:<br />
          1, The project address: <span class="text-heavy">{{ quotation.site }}</span>, is correct<br />
          2, The correct insurance is in place for this job<br />
          <div
            class="pt-2"
            v-if="quotation.customerSendOaEmail === true">
            <v-text-field
              label="Send order acknowledgement email to (required):"
              required
              v-model="quotation.contactEmail"></v-text-field>
            <p class="text-small text-red" v-if="oaEmailError">
              You must enter an email address.
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green lighten-2"
            small
            @click="convertQuotation"
          >
            {{ $store.state.username }} Confirming Job
          </v-btn>
          &nbsp;
          <v-btn
            small
            @click="showCheckBox = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="message === 'This job has been converted'"
            color="orange darken-2"
            text
            @click="goToJobCard"
          >
            View Job Card
          </v-btn>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPostcodeWarning"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          The postcode is invalid. Please update.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showPostcodeWarning = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Directions from '@/components/googlemaps/Directions.vue';
import UploadPo from '@/components/Quotations/UploadPO.vue';
import { VueEditor } from "vue2-editor";
import axios from '../../axios';

export default {
  name: 'QuotationsConvert',
  components: {
    appDirections: Directions,
    appUploadPo: UploadPo,
    VueEditor,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      quotation: {
        contact_id: 0,
        postCode:'',
        purchase_order: '',
        purchase_order_uri: '',
      },
      message: '',
      oaEmailError: false,
      showMessage: false,
      showCheckBox: false,
      contacts: [],
      addNewContact: false,
      newContact: {},
      uploadPo: false,
      showPostcodeWarning: false,
      menu: false,
      deliveryDate: null,
    };
  },
  watch: {
    deliveryDate () {
      this.quotation.delivery_date = this.formatDate(this.deliveryDate);
    },
    quotation: {
      handler(value) {
        let contact = {};
        for (let i = 0; this.contacts.length > i; i += 1) {
          if (this.contacts[i].id === value.siteContactId) {
            contact = this.contacts[i];
          }
        }
        this.quotation.siteContact = contact.name;
        this.quotation.siteEmail = contact.email;
        this.quotation.siteTel = contact.tel;
      },
      deep: true,
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    checkPostcode() {
      const postcode = this.quotation.postCode;
      if (postcode.length < 6) {
        this.showPostcodeWarning = true;
      } else {
        this.showCheckBox = true;
      }
    },
    completeUpload(uri) {
      this.quotation.purchase_order_uri = uri;
    },
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.quotation = response.data;
        this.getContacts();
      });
    },
    getContacts() {
      axios.get(`/contacts/getByCustomerId/${this.quotation.customerId}.json?token=${this.token}`)
      .then((response) => {
        this.contacts = response.data;
      });
    },
    convertQuotation() {
      let proceed = false;
      if(this.quotation.customerSendOaEmail === true) {
        if (this.quotation.contactEmail.length > 5) {
          proceed = true;
        }
      } else {
        proceed = true;
      }
      if (proceed === true) {
        const postData = {};
        postData.user = this.userid;
        postData.quotation = this.quotation;
        axios.post(`/quotations/convert.json?token=${this.token}`, postData)
        .then((response) => {
          this.showCheckBox = false;
          if (response.data.result === true) {
            this.message = response.data.message;
            this.showMessage = true;
          } else {
            this.message = response.data.message;
            this.showMessage = true;
          }
        });
      } else {
        this.oaEmailError = true;
      }
    },
    cancelNewContact() {
      this.addNewContact = false;
      this.newContact = null;
    },
    saveNewContact() {
      const postData = {};
      postData.customerId = this.quotation.customerId;
      postData.contact = this.newContact;
      axios.post(`/contacts/addFromQuote.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotation.siteContactId = response.data.contact.id;
          this.quotation.siteContact = response.data.contact.full_name;
          this.quotation.siteTel = response.data.contact.tel;
          this.quotation.siteEmail = response.data.contact.email;
          const cont = {};
          cont.id = response.data.contact.id;
          cont.name = response.data.contact.full_name;
          cont.email = response.data.contact.email;
          cont.tel = response.data.contact.tel;
          this.contacts.push(cont)
          this.addNewContact = false;
          this.newContact = {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
    populateDistances(value) {
      this.quotation.distance = value.distance;
      this.quotation.travel_hours = value.duration;
    },
    goToJobCard() {
      this.$router.push(`/live-jobs/view/${this.$route.params.id}`);
    },
  },
  mounted() {
    this.getQuotation();
  },
};
</script>
