<template>
  <div class="uploads"></div>
</template>

<script>
import axios from '../../axios';

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Transloadit from '@uppy/transloadit'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
  name: 'UploadPO',
  props: [
    'quotationId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
      },
    });
    uppy.use(Dashboard, {
      inline: true,
      width: '100%',
      target: '.uploads',
      proudlyDisplayPoweredByUppy: false,
    })
    .use(Transloadit, {
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
        template_id: 'f73197cf10564cdf814d34642589e166',
      },
      waitForEncoding: true,
      waitForMetadata: false,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: null,
      fields: {},
      limit: 0,
    })
    .on('complete', (results) => {
      const postData = {};
      postData.results = results;
      postData.title = this.title;
      postData.forCrews = this.forCrews;
      axios.post(`/quotations/addPo/${this.quotationId}.json?token=${this.token}`, postData)
        .then((response) => {
          const uri = response.data.uri;
          this.$emit('closeUploader', uri);
          uppy.reset();
        });
    });
  },
};
</script>

<style lang="scss">
.uppy-Dashboard-inner {
  max-height: 350px!important;
}
</style>